@import "primeicons/primeicons.css";
@import "primeng/resources/themes/lara-light-indigo/theme.css";
@import "primeng/resources/primeng.css";
//@import "@aws-amplify/ui-angular/theme.css";

$gutter: 1rem; //for primeflex grid system
//@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
//@import "../node_modules/primeng/resources/primeng.min.css";
//@import "../node_modules/primeflex/primeflex.scss";
//@import "../node_modules/primeicons/primeicons.css";


/////////////////////////////////////////////////////////////////////////////
/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Demos */
@import "assets/layout/styles/flags/flags.css";
@import "assets/layout/styles/badges.scss";
@import "assets/layout/styles/code.scss";